import Choices from "choices.js";
import classNames from "classnames";

export const initChoices = () => {
  const choicesJSelementsMultiple =
    document.getElementsByClassName("selectmultiple");
  const choicesJSelements = document.getElementsByClassName("select");
  [...choicesJSelements, ...choicesJSelementsMultiple].map((element) => {
    const {
      errorselector,
      maxitemcount,
      explodeitemafterdash,
      additionalClassesContainerInner,
      additionalClassesContainerOuter,
      shouldsort,
    } = element.dataset;
    const shouldSort = shouldsort !== "false";
    const maxItemCountNumber = Number(maxitemcount);
    const explodeItemAfterDash = explodeitemafterdash === "true";
    return createNewChoicesElemenet({
      additionalClassesContainerInner,
      additionalClassesContainerOuter,
      element,
      errorselector,
      explodeItemAfterDash,
      maxItemCountNumber,
      removeItemButton: true,
      shouldSort,
    });
  });
};

export const createNewChoicesElemenet = ({
  element,
  explodeItemAfterDash,
  additionalClassesContainerInner,
  additionalClassesContainerOuter,
  errorselector,
  maxItemCountNumber,
  removeItemButton,
  shouldSort,
}) => {
  const choicesInstance = new Choices(element, {
    callbackOnCreateTemplates: (template) => {
      return {
        item: (config, data) => {
          const globalClasses = config.classNames;
          const { removeItemButton } = config;
          const ariaSelected = data.active ? 'aria-selected="true"' : "";
          const ariaDisabled = data.disabled ? 'aria-disabled="true"' : "";

          const localClasses = classNames(globalClasses.item, {
            [globalClasses.highlightedState]: data.highlighted,
            [globalClasses.itemSelectable]: !data.highlighted,
            [globalClasses.placeholder]: data.placeholder,
          });

          const removeItemButtonTemplate = removeItemButton
            ? `<button
         type="button"
         class="${globalClasses.button}"
         data-button
         aria-label="Remove item: '${data.value}'"
         >
         Remove item
       </button>`
            : "";

          return template(`
      <div
        class="${localClasses}"
        data-item
        data-id="${data.id}"
        data-value="${data.value}"
        data-custom-properties='${data.customProperties}'
        data-deletable
        ${ariaSelected}
        ${ariaDisabled}
        >
        ${explodeItemAfterDash ? getFirstLabelPart(data.label) : data.label}
        ${removeItemButtonTemplate}
      </div>
    `);
        },
      };
    },
    classNames: {
      containerInner: `${
        additionalClassesContainerInner
          ? additionalClassesContainerInner
          : "choices__inner"
      } ${errorselector ? errorselector : ""} `,
      containerOuter: additionalClassesContainerOuter
        ? additionalClassesContainerOuter
        : "choices",
    },
    itemSelectText: "",
    loadingText: "Suchen...",
    maxItemCount: maxItemCountNumber,
    maxItemText: (maxItemCount) => {
      return `Sie können höchstens ${maxItemCount} Einträge auswählen`;
    },
    noChoicesText: "Keine Auswahl verfügbar",
    noResultsText: "Keine Ergebnisse",
    removeItemButton,
    searchFloor: 1,
    shouldSort,
    fuseOptions: {
      includeScore: true,
    },
  });
  element.addEventListener(
    "addItem",
    () => {
      choicesInstance.hideDropdown();
    },
    false
  );
  return choicesInstance;
};

const getFirstLabelPart = (label) => {
  const SEPARATOR = "-";
  if (label.includes(SEPARATOR)) {
    const labelParts = label.split(SEPARATOR);
    return labelParts[0].trim();
  } else {
    return label;
  }
};
