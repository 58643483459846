/* Project specific Javascript goes here. */
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import * as bootstrap from "bootstrap";
import * as Ladda from "ladda";
import "tinymce";
import "../scss/app.scss";
import { initChoices } from "./utils/choicesConfig";

window.Ladda = Ladda;

require("expose-loader?exposes=React!react");
require("expose-loader?exposes=ReactDOM!react-dom");
require("expose-loader?exposes=ReactComponents!./components");
require("ladda/dist/ladda-themeless.min.css");

// use Ladda for all forms
Ladda.bind("button[type=submit]");

function maybeAnimateAlert() {
  const alert = document.getElementById("alert");
  if (alert) {
    setTimeout(() => {
      alert.classList.add("applicationToast-enter-active");
    }, 200);
    // hide after 5 sec
    setTimeout(() => {
      alert.className = alert.className.replace(
        "applicationToast-enter-active",
        "applicationToast-exit-active"
      );
    }, 5000);
  }
}

function handleTrElementClick(e) {
  // is the dropdown not yet opened?
  if (
    e.target.nodeName === "TD" &&
    e.target.closest("tr").querySelector(".dropdown-item")
  ) {
    e.stopPropagation();
    const el = e.target
      .closest("tr")
      .querySelector("[data-bs-toggle='dropdown']");
    const dropdown = new bootstrap.Dropdown(el);
    dropdown.toggle();
  }
}

function initDataHrefLinks() {
  const dataHrefElements = document.querySelectorAll("tr[data-href]");
  [...dataHrefElements].map((element) => {
    element.addEventListener("click", (evt) =>
      handleTrElementClick(evt, element)
    );
  });
}

window.initChoices = initChoices;

/**
 *
 */
document.addEventListener("DOMContentLoaded", function () {
  maybeAnimateAlert();
  initDataHrefLinks();

  initToasts();
  initPopovers();
});

if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://c7ac5bb862b84ecda0e89fff2bc16550@o143463.ingest.sentry.io/1759978",
    integrations: [new Integrations.BrowserTracing()],
  });
}
function initPopovers() {
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });
}

function initToasts() {
  var toastElList = [].slice.call(document.querySelectorAll(".toast"));
  var toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl, {});
  });
}
