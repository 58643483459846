import Axios from "axios";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";

const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export default function DownloadButton(props) {
  const [loading, setLoading] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(
    Axios.CancelToken.source()
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const response = await Axios.get(props.url, {
        // Assign the source.token to this request
        cancelToken: cancelTokenSource.token,
        responseType: "blob",
        timeout: 30000,
      });
      FileSaver.saveAs(
        new Blob([response.data], { type: props.type }),
        props.filename
      );
    } catch (error) {
      // Is this no error because we cancelled it ourselves?
      if (!Axios.isCancel(error)) {
        setError(true);
      }
    } finally {
      resetButton();
    }
  };

  const resetButton = () => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    setCancelTokenSource(source);
    setLoading(false);
  };

  const handleClick = async () => {
    if (loading) {
      await cancelTokenSource.cancel("Operation canceled by the user.");
      resetButton();
    } else {
      setLoading(true);
      loadData();
    }
  };

  const width = 8;
  const dashOffset = 2 * Math.PI * width;

  let buttonIcon = null;
  // FileSaver.js doesn't work very well with Safari, because of
  // https://github.com/eligrey/FileSaver.js/issues/12
  // So for Safari we'll not use any fancy JS stuff but a simple href
  if (isSafari()) {
    return (
      <a className="btn btn-primary" href={props.url}>
        {props.title}
      </a>
    );
  } else if (error) {
    return (
      <button className="btn btn-primary" disabled>
        <i className="ion px-1 ion-ios-warning" />
        {props.title}
      </button>
    );
  } else if (loading) {
    return (
      <button className="btn btn-primary" disabled>
        <span
          className="spinner-border spinner-border-sm me-2"
          role="status"
        ></span>
        {props.title}
      </button>
    );
  } else
    return (
      <button onClick={handleClick} className="btn btn-primary">
        {props.title}
      </button>
    );
}
