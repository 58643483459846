import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";

const sucessImage = (
  <svg
    viewBox="0 0 24 24"
    role="presentation"
    aria-hidden="true"
    focusable="false"
    style={{
      display: "block",
      fill: "currentcolor",
      height: "12px",
      width: "12px",
    }}
  >
    <path
      d="m1.29 11.98c-.29-.3-.76-.31-1.06-.02s-.31.76-.02 1.06l6.75 7c .29.3.78.31 1.07.01l15.75-16c .29-.3.29-.77-.01-1.06s-.77-.29-1.06.01l-15.21 15.45z"
      fillRule="evenodd"
    />
  </svg>
);

const errorImage = (
  <svg
    viewBox="0 0 24 24"
    role="presentation"
    aria-hidden="true"
    focusable="false"
    style={{
      display: "block",
      fill: "currentcolor",
      height: "12px",
      width: "12px",
    }}
  >
    <path
      d="m23.25 24c-.19 0-.38-.07-.53-.22l-10.72-10.72-10.72 10.72c-.29.29-.77.29-1.06 0s-.29-.77 0-1.06l10.72-10.72-10.72-10.72c-.29-.29-.29-.77 0-1.06s.77-.29 1.06 0l10.72 10.72 10.72-10.72c.29-.29.77-.29 1.06 0s .29.77 0 1.06l-10.72 10.72 10.72 10.72c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22"
      fillRule="evenodd"
    />
  </svg>
);

const matchedCase = [
  {
    message: "Enthält Großbuchstaben",
    regex: "[A-Z]",
  }, // Uppercase Alpabates
  {
    message: "Enthält Ziffern",
    regex: "[0-9]",
  }, // Numbers
  {
    message: "Enthält Kleinbuchstaben",
    regex: "[a-z]",
  }, // Lowercase Alphabates
  {
    message: "Mindestens 8 Zeichen",
    regex: "^.{9,254}$",
  }, // Lowercase Alphabates
];

export default function PasswordInput(props) {
  const [currentContent, setCurrentContent] = useState(null);
  const [shouldDisplayErrors, setShouldDisplayErrors] = useState(
    !!props.errors && props.errors.length > 0
  );
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    const body = event.target.value;

    setCurrentContent(body);
  };

  const handleFocus = () => setShouldDisplayErrors(true);
  const handleShowPasswordClick = () => setShowPassword(!showPassword);

  const renderPasswordNotices = () => {
    // Create an array and push all possible values that you want in password

    const validationMessages = [];

    if (props.errors && props.errors.length > 0 && !currentContent) {
      props.errors.map((value) => {
        if (serverMessagesContainThisError(value)) {
          validationMessages.push({
            color: "text-danger",
            image: errorImage,
            message: value,
          });
        } else {
          validationMessages.push({
            color: "text-success",
            image: sucessImage,
            message: value,
          });
        }
      });
    } else {
      matchedCase.map((value) => {
        if (!new RegExp(value.regex).test(currentContent || "")) {
          validationMessages.push({
            color: "text-dark",
            image: errorImage,
            message: value.message,
          });
        } else {
          validationMessages.push({
            color: "text-success",
            image: sucessImage,
            message: value.message,
          });
        }
      });
    }

    return validationMessages.map(({ message, color, image }) =>
      renderPasswordNotice(message, color, image)
    );
  };

  const serverMessagesContainThisError = (message) =>
    matchedCase.find((matcher) => matcher.message === message);

  const renderPasswordNotice = (message, color, image) => (
    <div key={message} style={{ textAlign: "left" }}>
      <div className={`font-size-80-percent mt-2 #{color}`}>
        <div
          style={{
            borderSpacing: "0px",
            display: "table",
            width: "100%",
          }}
        >
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <div style={{ marginRight: "8px" }}>
              <div
                role="img"
                aria-label="Regel wurde nicht anerkannt"
                style={{ height: "12px", width: "12px" }}
              >
                {image}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              width: "100%",
            }}
          >
            <span className={color}>{message}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const hasErrorFromServer = props && props.errors && props.errors.length > 0;
  const inputErrorClass = hasErrorFromServer ? "is-invalid" : "";

  return (
    <div className="form-group row">
      <div className="col">
        <label
          for={`id_${props.fieldName}`}
          className="form-label requiredField"
        >
          {props.label}
          <span className="asteriskField">*</span>
        </label>
        <div className="input-group">
          <input
            className={`form-control ${inputErrorClass}`}
            id={props.fieldId}
            data-testid="password-input"
            name={props.fieldName}
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            value={currentContent || ""}
            onFocus={handleFocus}
          />
          <div
            className="input-group-text font-size-24"
            role="button"
            onClick={handleShowPasswordClick}
            data-testid="show-password-button"
          >
            {showPassword ? (
              <i className="ion px-1 ion-ios-eye-off" />
            ) : (
              <i className="ion px-1 ion-ios-eye" />
            )}
          </div>
        </div>

        {hasErrorFromServer && (
          <div className="invalid-feedback d-block">
            Bitte beachten Sie die Passwort-Sicherheitsregeln.
          </div>
        )}
        <CSSTransition
          in={shouldDisplayErrors}
          timeout={500}
          classNames="passwordInput"
          unmountOnExit={true}
        >
          <div>{shouldDisplayErrors && renderPasswordNotices()}</div>
        </CSSTransition>
      </div>
    </div>
  );
}
